import React, { useState } from 'react';

import FilterDropdown from './FilterDropdown';
import Dropdown from '../../Suppliers/Filters/Dropdown';
import SelectedCategories from './SelectedCategories';

function StatisticsFilter({
  levels,
  selectedCategoryId,
  onCategoryChange,
  getCategoryName,
  onRemoveCategory,
  isOpen,
  setIsOpen,
  setSelectedStatus,
  tags,
  selectedTag,
  handleTagsFilter,
  countries,
  selectedCountry,
  handleCountryFilter,
  weights,
  selectedWeight,
  handleWeightFilter,
  demoIsEnabled,
}) {
  const [navigationPath, setNavigationPath] = useState([]);

  return (
    <div className="mb-[18px]">
      <div className="flex gap-2 flex-wrap">
        <FilterDropdown
          levels={levels}
          selectedCategoryId={selectedCategoryId}
          onChange={onCategoryChange}
          navigationPath={navigationPath}
          setNavigationPath={setNavigationPath}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setSelectedStatus={setSelectedStatus}
        />
        {tags && tags.length > 0 && (
          <Dropdown
            name="TAGS"
            handleChange={handleTagsFilter}
            value={selectedTag}
            options={tags}
            showLabel={false}
          />
        )}
        {demoIsEnabled && (
        <>
          <Dropdown
            name="COUNTRIES"
            handleChange={handleCountryFilter}
            value={selectedCountry}
            options={countries}
            showLabel={false}
          />
          <Dropdown
            name="Pondération"
            handleChange={handleWeightFilter}
            value={selectedWeight}
            options={weights}
            showLabel={false}
          />
        </>
        )}
      </div>
      {selectedCategoryId && (
        <SelectedCategories
          categoryId={selectedCategoryId}
          getCategoryName={getCategoryName}
          onRemove={onRemoveCategory}
          setNavigationPath={setNavigationPath}
        />
      )}
    </div>
  );
}

export default StatisticsFilter;
