import { sanitize } from 'dompurify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import pen from '../../../../assets/ui/pen.svg';
import PartnerProfileCommentForm from '../Modal/Forms/PartnerProfileCommentForm';
import useModal from '../../../../hooks/useModal';

function PartnerProfileComment({ profile, partner, hasWriteAccess }) {
  const [profileComment, setProfileComment] = useState();
  const [formContext, setFormContext] = useState({});

  const { open } = useModal();

  const { get } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });
  const { t } = useTranslation();

  const getProfileComment = async () => {
    const response = await get(`partner/${partner.id}/profiles/${profile.id}/comment`);
    setProfileComment(response);
  };

  useEffect(() => {
    try {
      getProfileComment();
    } catch (error) {
      throw new Error(error);
    }
  }, [profile]);

  return (
    <div className="bg-white p-4 mb-4 border border-solid border-greySix rounded">
      <div className="flex items-center justify-between">
        <h2 className="text-greyOne text-base">
          {t('PARTNER_PROFILE_COMMENT')}
        </h2>
        {hasWriteAccess && (
        <div className="bottom-0 flex justify-end">
          <button
            type="button"
            className="border-0 bg-white"
            onClick={() => {
              setFormContext({
                action: profileComment ? 'edit' : 'create',
                modalTitle: t('EDIT_COMMENT'),
                title: `${t('COMMENT_FOR')} ${profile.name}`,
                profileId: profile.id,
                partnerId: partner.id,
                size: 'modal-lg',
                showUrlForm: true,
                data: {
                  profileComment: profileComment?.content,
                  profileCommentId: profileComment?.id,
                  getProfileComment,
                },
              });
              open('partner-profile-comment-modal');
            }}
          >
            <img src={pen} width={22} height={22} alt="edit statut" />
          </button>
        </div>
        )}
      </div>
      <h3 className="text-xs text-greyThree font-normal">
        {t('WRITTEN_BY')} {partner.name}
      </h3>
      <div className="mt-4 text-sm" dangerouslySetInnerHTML={{ __html: sanitize(profileComment?.content) }} />

      {hasWriteAccess ? (
        <PartnerProfileCommentForm formContext={formContext} />
      ) : ('')}
    </div>
  );
}

export default PartnerProfileComment;
