import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Dropdown from './Dropdown';

import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function CurrentProgress() {
  const { setLoading } = useContext(TableLoadingContext);
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const options = [
    {
      name: 'CURRENT_PROGRESS_IN_LINE',
      value: 'commited',
    },
    {
      name: 'CURRENT_PROGRESS_NOT_IN_LINE',
      value: 'not_in_line',
    },
    {
      name: 'NO_COMMITMENT',
      value: 'not_commited',
    },
  ];

  const handleCurrentProgressFilter = (value) => {
    setLoading(true);
    if (value === '') {
      searchParams.delete('current_progress_filter');
      searchParams.set('p', 0);
    } else {
      searchParams.set('current_progress_filter', value);
      searchParams.set('p', 0);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('current_progress_filter')) {
      setValue(searchParams.get('current_progress_filter'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name="CURRENT_PROGRESS"
      handleChange={handleCurrentProgressFilter}
      options={options}
      value={value}
    />
  );
}

export default CurrentProgress;
