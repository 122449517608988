import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Dropdown from './Dropdown';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function Tags({ tags }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const options = tags?.map((tag) => ({
    name: tag.name,
    value: tag.id,
  }));

  const handleTagsFilter = (value) => {
    setLoading(true);
    if (value === '') {
      searchParams.delete('tags_filter');
      searchParams.set('p', 0);
    } else {
      searchParams.set('tags_filter', value);
      searchParams.set('p', 0);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('tags_filter')) {
      setValue(searchParams.get('tags_filter'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name="TAGS"
      handleChange={handleTagsFilter}
      options={options}
      value={value}
    />
  );
}

export default Tags;
