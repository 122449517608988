import React, { useEffect, useRef } from 'react';
import Description from './Description';
import handleCategoryClick from './handleCategoryClick';
import findParentCategory from '../../../utils/findParentCategory';
import categoryIcons from '../../../utils/categoryIcons';

function CategoryCard({
  sector,
  index,
  sectors,
  loading,
  setLoading,
  QUERY_NAME,
  searchParams,
  setFilteredCategory,
  allLevels,
  setDescription,
  setSearchParams,
  setSelected,
  description,
  setSectors,
  selected,
}) {
  const descriptionRef = useRef(null);

  useEffect(() => {
    const div = document.getElementById(sector.level[0].id);
    if (div) {
      const handleAnimation = (isOpen) => {
        const targetHeight = isOpen ? `${div.scrollHeight + 100 + (descriptionRef.current ? 200 : 0)}px` : '0px';
        const animation = div.animate(
          [
            {
              maxHeight: isOpen ? '0px' : `${div.scrollHeight}px`,
              overflow: 'hidden',
              marginTop: isOpen ? '0px' : '24px',
            },
            {
              maxHeight: targetHeight,
              overflow: 'hidden',
              marginTop: isOpen ? '24px' : '0px',
            },
          ],
          {
            duration: 350,
            fill: 'forwards',
            easing: 'ease',
          },
        );

        animation.onfinish = () => {
          div.style.maxHeight = isOpen ? '100%' : '0px';
          div.style.marginTop = isOpen ? '24px' : '0px';
        };
      };

      if (sectors && sectors[index]?.level[0].id === sector?.level[0].id &&
          div.style.maxHeight === '0px' && index > 0) {
        handleAnimation(true);
      } else if (sectors[index]?.level[0].id !== sector?.level[0].id &&
                 div.style.maxHeight === '100%') {
        handleAnimation(false);
      } else if (sectors[index]?.level[0].id !== sector?.level[0].id) {
        div.style.maxHeight = '0px';
        div.style.marginTop = '0px';
      }
    }
  }, [sectors, index, sector, description]);

  const handleClick = (category) => {
    if (!loading) {
      if (selected && selected.find((sel) => sel.name === category.name)) {
        const newDescription = description.filter((_, idx) => idx !== index);
        setDescription(newDescription);

        const newSelected = selected.filter((sel) => sel.name !== category.name);
        setSelected(newSelected);

        if (searchParams.has(QUERY_NAME)) {
          searchParams.delete(QUERY_NAME);
          setSearchParams(searchParams);
        }

        if (setFilteredCategory) {
          setFilteredCategory(null);
        }
      } else {
        handleCategoryClick(
          category,
          index,
          sector.level[0].id,
          index,
          setLoading,
          QUERY_NAME,
          searchParams,
          setFilteredCategory,
          findParentCategory,
          allLevels,
          setDescription,
          setSearchParams,
          setSelected,
          description,
          sectors,
          setSectors,
        );
      }
    }
  };

  return (
    <div
      id={sector?.level[0].id}
      className="hidden md:block overflow-hidden"
    >
      <h3 className="text-sm text-greyOne">
        {sector.level.length > 0 && sector.level[0].name
          ? sector.level[0].name
          : `Niveau ${sector.level[0].position}`}
      </h3>

      <div className="grid grid-cols-4 gap-4 mt-6 max-xl:grid-cols-3 max-md:grid-cols-2">
        {sector.level.length > 0 &&
         sector.level[0].categories?.sort((a, b) => a.name.localeCompare(b.name))
           .map((category) => {
             const isSelected = selected && selected.find((sel) => sel.name === category.name);
             const isVisible = sectors && sectors[index]?.level[0].categories.find((cat) => cat.id === category.id);

             return (
               <button
                 type="button"
                 key={category.id}
                 id={category.name}
                 onClick={() => handleClick(category)}
                 className={`flex items-center p-3 text-sm rounded-xl border border-blueBorder transition-all duration-200
                   hover:border-secondarySkyBlue shadow-[0_1px_2px_0_rgba(0,0,0,0.05)] ${!isVisible ? 'hidden' : ''} ${isSelected
                   ? 'font-semibold text-primaryNewBlue bg-blueFlashLight border-secondarySkyBlue'
                   : 'font-normal text-greyThree bg-white'}`}
               >
                 {category.icon && (
                   <div className="w-[21px] h-[21px] mr-2.5">
                     <div
                       className="w-full h-full bg-primary bg-center bg-no-repeat"
                       style={{
                         WebkitMaskImage: `url(${category.icon ? categoryIcons.find((icon) => icon.value === category.icon)?.icon : ''})`,
                         maskImage: `url(${category.icon ? categoryIcons.find((icon) => icon.value === category.icon)?.icon : ''})`,
                         WebkitMaskSize: 'contain',
                         maskSize: 'contain',
                         WebkitMaskRepeat: 'no-repeat',
                         maskRepeat: 'no-repeat',
                         WebkitMaskPosition: 'center',
                         maskPosition: 'center',
                       }}
                     />
                   </div>
                 )}
                 <div className="self-center">{category.name}</div>
               </button>
             );
           })}
      </div>

      {sector.level.length > 0 &&
       sector.level[0].categories?.sort((a, b) => a.name.localeCompare(b.name))
         .map((category, indexCategory) => (
           (category.description || category.resources?.length > 0) &&
           description[index] &&
           description[index].id === category.id && (
             <Description
               key={indexCategory}
               sector={sector}
               category={category}
               index={index}
               description={description}
               descriptionRef={descriptionRef}
             />
           )
         ))}
    </div>
  );
}

export default CategoryCard;
