import React, { useRef } from 'react';
import blankSector from '../../../assets/ui/blank-sector.svg';
import handleSelectChange from './handleSelectChange';
import Description from './Description';
import categoryIcons from '../../../utils/categoryIcons';

function CategoryCardMobile({
  sector,
  index,
  sectors,
  setSelected,
  description,
  setDescription,
  setFilteredCategory,
  searchParams,
  setSearchParams,
  allLevels,
  setSectors,
  QUERY_NAME,
}) {
  const descriptionRef = useRef(null);

  return (
    <div className={`md:hidden ${index > 0 ? 'mt-6' : ''}`}>
      <h3 className="text-sm text-greyHeavy mb-2">
        {sector.level.length > 0 && sector.level[0].name
          ? sector.level[0].name
          : `Niveau ${sector.level[0].position}`}
      </h3>

      <div className="relative">
        <select
          name="sector-select"
          id="sector-select"
          value={description[index]?.id ? description[index]?.id : 0}
          style={{
            backgroundImage: `url(${
              description[index]?.id && description[index]?.svg
                ? categoryIcons.find(
                  (icon) => icon.value === description[index]?.svg,
                )?.icon
                : blankSector
            })`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '12px center',
            backgroundSize: '2em',
          }}
          className="w-full py-3 pl-[52px] pr-3 text-sm font-semibold rounded-xl border-2 border-secondarySkyBlue bg-blueFlashLight text-primaryNewBlue shadow-sm appearance-none focus:outline-none"
          onChange={(e) => handleSelectChange(
            e,
            index,
            description,
            sector.level[0].id,
            allLevels,
            sectors,
            setSectors,
            setSelected,
            setDescription,
            setFilteredCategory,
            searchParams,
            setSearchParams,
            QUERY_NAME,
          )}
        >
          <option value={0} disabled>
            CHOISIR UN SECTEUR
          </option>
          {sector &&
            sector.level[0].categories?.map((category) => (
              <option
                key={category.id}
                data-description={category.description}
                data-svg={category.icon}
                data-resources={JSON.stringify(category.resources)}
                value={category.id}
              >
                {category.name}
              </option>
            ))}
        </select>

        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-primaryNewBlue"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>

      {sector.level.length > 0 &&
        sector.level[0].categories
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map(
            (category, indexCategory) => (category.description || category.resources?.length > 0) &&
              description[index] &&
              description[index].id === category.id && (
                <Description
                  key={indexCategory}
                  sector={sector}
                  category={category}
                  index={index}
                  description={description}
                  descriptionRef={descriptionRef}
                />
            ),
          )}
    </div>
  );
}

export default CategoryCardMobile;
