import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { Input } from '@Openclimat/openclimat-component-library';
import { CKEditor } from 'ckeditor4-react';
import { useTranslation } from 'react-i18next';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';
import getUserLocal from '../../../../../utils/getUserLocal';
import handleResponse from '../../../../../utils/handleResponse';

export default function QuestionQualitativeFormModal({ data, profileId, fetchData }) {
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({});
  const { close } = useModal();
  const [editor, setEditor] = useState(null);

  const { t } = useTranslation();

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/questions/${data?.id}`, {
    credentials: 'include',
  });

  const linkValue = watch('url');
  const linkTitleValue = watch('urlTitle');

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };

  useEffect(() => {
    if (editor) {
      editor.setData(data ? data.answer?.answer : null);
    }
    register('answer', {
      required: data?.is_required ? t('ANSWER_REQUIRED') : false,
    });
  }, [data]);

  const onSubmit = async (formData) => {
    try {
      const finalData = {
        question_type: data.type,
        answer: data?.is_required ? formData.answer || '' : formData.answer,
        url_id: data.answer?.contentListItems ? data.answer?.contentListItems[0]?.id : null,
        url_title: formData.urlTitle,
        url: formData.url,
        locale: getUserLocal(),
      };

      if (data?.answer?.id) {
        await put(`answer/${data.answer.id}`, finalData);
      } else {
        await post('answer', finalData);
      }
      if (response.ok) {
        close();
        fetchData();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <TemplateFormModal
      modalId="question-qualitative-modal"
      formId="question-qualitative-form"
      title="Éditer une réponse"
    >
      <form
        id="question-qualitative-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="p-4 md:p-5 space-y-4">
          <div className="mb-4">
            <label htmlFor="answer" className="block text-base font-medium text-greyOne mb-2.5">
              {data?.info}{data?.is_required ? '*' : ''}
            </label>
            <CKEditor
              initData={data ? data.answer?.answer : null}
              onLoaded={onBeforeLoad}
              onChange={(value) => setValue('answer', value.editor.getData().replace(/(\r\n|\n|\r)/gm, ''))}
              required={false}
              config={{
                toolbar: [
                  ['Bold', 'Italic', 'Underline', 'Strike'],
                  ['NumberedList', 'BulletedList'],
                ],
                versionCheck: false,
              }}
            />
            {errors.answer && (
              <div className="text-sm text-redError mt-1">{errors.answer.message}</div>
            )}
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="urlTitle"
              label="Titre du lien"
              defaultValue={data?.answer?.contentListItems && data?.answer?.contentListItems[0]?.name}
              errors={errors}
              register={register}
              validationSchema={{
                required: linkValue ? 'Le titre du lien est requis' : false,
              }}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="url"
              label="Lien"
              errors={errors}
              register={register}
              defaultValue={data?.answer?.contentListItems && data?.answer?.contentListItems[0]?.url}
              validationSchema={{
                required: linkTitleValue ? 'Le lien est requis' : false,
                pattern: {
                  value: /^(ftp|http|https):\/\/[^ "]+$/,
                  message: "Le lien n'est pas valide",
                },
              }}
            />
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}
