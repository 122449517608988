import React from 'react';
import cross from '../../../../assets/ui/cross-modal.svg';

function SelectedCategories({ categoryId, getCategoryName, onRemove, setNavigationPath }) {
  const handleCategoryRemove = (categoryId) => {
    onRemove(categoryId);
    setNavigationPath([]);
  };

  return (
    <div className="flex flex-wrap gap-2 mt-3">
      <button
        type="button"
        onClick={() => handleCategoryRemove(categoryId)}
        className="bg-white flex items-center gap-[14px] px-4 py-[9px] border border-solid border-greySix rounded-full text-sm text-primaryNewBlue font-moskauGrotesk font-medium"
      >
        <span className="-mb-[3px]">{getCategoryName(categoryId)}</span>
        <img src={cross} width={20} alt="Delete" />
      </button>
    </div>
  );
}

export default SelectedCategories;
