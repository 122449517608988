import React from 'react';
import { Link } from 'react-router-dom';
import getLocalLink from '../../../../utils/getLocalLink';
import RightArrow from '../../../../assets/ui/right-arrow.svg';
import label from '../../../../assets/ui/label.svg';

function Results({ profiles, handleClick }) {
  return profiles && profiles.length > 0 && (
    <div className="absolute mt-3 min-w-[310px] bg-white rounded shadow-lg transform-gpu z-50">
      {profiles.map((profile, index) => (
        <Link
          key={index}
          to={getLocalLink(`/profile/${profile.slug}`)}
          className="flex items-center justify-between p-1 no-underline text-black hover:bg-backgroundCleanBlue text-sm"
          onClick={() => handleClick()}
        >
          <div className="flex items-center">
            <img
              src={`${process.env.REACT_APP_FILES_DOMAIN}${profile.logo_url}`}
              alt={`search-result ${profile.name}`}
              className="h-10 w-10 object-contain mr-1"
            />
            <span className="mr-2">{profile.name}</span>
            {profile.is_managed ? (
              <img
                src={label}
                alt="verified"
                className="w-[18px] h-[18px] text-primary"
              />
            ) : ''}
          </div>
          <img src={RightArrow} alt="right arrow" className="float-right mr-3" />
        </Link>
      ))}
    </div>
  );
}

export default Results;
