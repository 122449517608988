import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import getUserLocal from '../../../../../utils/getUserLocal';
import TableOfContents from './TableOfContents';
import QuestionContainer from './QuestionsContainer';
import Header from '../../Header/Header';
import getQuestions from '../../../../../utils/getQuestions';
import getAnsweredQuestionsCount from '../../../../../utils/getAnsweredQuestionsCount';
import QuestionQualitativeFormModal from '../../Modal/Forms/QuestionQualitativeFormModal';
import QuestionQuantitativeFormModal from '../../Modal/Forms/QuestionQuantitativeFormModal';
import DeleteConfirmationModal from '../../Modal/Forms/DeleteConfirmationModal';
import handleResponse from '../../../../../utils/handleResponse';

function PrivateQuestions({ view }) {
  const [data, setData] = useState();
  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [itemToDelete, setItemToDelete] = useState();

  const { t } = useTranslation();

  const { profile, partner, hasWriteAccess } = useOutletContext();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const QUESTION_TYPE = 6;

  const fetchData = async () => {
    try {
      const response = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), QUESTION_TYPE, profile.typology_id, partner.id);

      if (api.response.ok) {
        setQuestions(response.questions);
        setSummary(response.table_contents);
        const answeredNormal = getAnsweredQuestionsCount(response.table_contents, true);
        setNumberOfAnsweredQuestions(answeredNormal);
        const totalQuestions = response.questions.length;
        setNumberOfQuestions(response.questions.length);
        const progressionBar = (answeredNormal * 100) / totalQuestions;
        setProgressionBar(parseInt(progressionBar, 10));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async () => {
    try {
      await api.del(`/profiles/${profile.id}/content-value/${itemToDelete}`);
      if (api.response.ok) {
        fetchData();
      }
    } catch (error) {
      handleResponse(api.response);
      throw new Error(error);
    }
  };

  return (
    <div id="profile-page">
      <Header view={view} />
      <div className="container py-10 mx-auto px-5">
        <div className="lg:grid grid-cols-12 gap-6">
          <div className="col-span-4 col-start-1">
            <TableOfContents
              hasWriteAccess={hasWriteAccess}
              progressionBar={progressionBar}
              numberOfQuestions={numberOfQuestions}
              numberOfAnsweredQuestions={numberOfAnsweredQuestions}
              summary={summary}
              partnerInfo={partner}
              partner={partner}
              isPublished
            />
          </div>
          <div className="col-span-8 col-start-5">
            <QuestionContainer
              profile={profile}
              partner={partner}
              questions={questions}
              hasWriteAccess={hasWriteAccess}
              fetchData={fetchData}
              view={view}
              setData={setData}
              setItemToDelete={setItemToDelete}
            />
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <QuestionQualitativeFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionQuantitativeFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <DeleteConfirmationModal modalId="delete-confirmation-modal-questions" handleDeleteFunction={handleDelete} title={t('DELETE_COMFIRMATION_MODAL.TITLE_A')} message={t('DELETE_COMFIRMATION_MODAL.TITLE_B')} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default PrivateQuestions;
