import React, { useEffect, useState } from 'react';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import bug from '../../../../assets/ui/bug.svg';
import getLocalLink from '../../../../utils/getLocalLink';
import getTabsQuestionsCount from '../../../../utils/getTabsQuestionsCount';
import getUserLocal from '../../../../utils/getUserLocal';
import questionType from '../../../../utils/questionType';
import handleResponse from '../../../../utils/handleResponse';
import verifyAuthorization from '../../../../utils/verifyAuthorization';

import Features from '../../../../features.json';

import './Tab.scss';

function Tabs({ view }) {
  const [categoryFilter, setCategoryFilter] = useState();
  const [views, setViews] = useState([]);
  const { partner, profile, params, session, isDashboard, features } = useOutletContext();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}`);

  const isDemoScreen = features.includes(Features.mockedDemoScreens) && verifyAuthorization(session, ['ROLE_ADMIN']);

  const viewsInfo = [
    {
      url: getLocalLink(`${params?.slug}`),
      label: 'OVERVIEW',
      view: 'preview',
      display: true,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/partner_data`),
      label: 'PARTNER_DATA',
      view: 'partner_data',
      display: true,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/product_data`),
      label: 'PRODUCT_DATA',
      view: 'product_data',
      display: true,
      disabled: true,
      demo: true,
    },
    {
      url: getLocalLink(`${params?.slug}/approach`),
      label: 'OUR_APPROACH',
      view: 'approach',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/actions`),
      label: 'ACTION_PLAN',
      view: 'actions',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/other_esg`),
      label: 'OTHER_ESG',
      view: 'other_esg',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/co2_by_product`),
      label: 'CO2_BY_PRODUCT',
      view: 'co2_by_product',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/climat`),
      label: 'CLIMAT',
      view: 'climat',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/environment`),
      label: 'ENVIRONMENT',
      view: 'environment',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/governance`),
      label: 'GOVERNANCE',
      view: 'governance',
      display: false,
      disabled: false,
    },
    {
      url: getLocalLink(`${params?.slug}/questions`),
      label: getUserLocal() === 'fr' ? t('PRIVATE_QUESTIONS_OF') + partner.name : partner.name + t('PRIVATE_QUESTIONS_OF'),
      view: 'private_questions',
      display: false,
      disabled: false,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTabsQuestionsCount(
          api.get,
          api.response,
          profile.type,
          partner.id,
          profile.typology_id,
          getUserLocal(),
          profile.id,
        );

        if (response) {
          const updatedViews = viewsInfo.map((view) => {
            const display = response.some((question) => ((question.is_required ||
                (!question.is_required && question.answer) ||
                session?.roles?.includes('ROLE_ADMIN') ||
                session?.roles?.includes('ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS')) &&
                (questionType[question.tab] === view.view)) ||
                view.view === 'preview') ||
                (view.view === 'partner_data' && isDemoScreen) ||
                (view.view === 'product_data' && isDemoScreen);

            let disabled = false;
            if (
              view.view !== 'preview' &&
              view.view !== 'partner_data' &&
                view.view !== 'environment' &&
                view.view !== 'governance' &&
                view.view !== 'climat') {
              if (profile.type !== 2 && profile.is_managed === false) {
                disabled = true;
              }
              if (profile.type === 2 && !profile.is_premium) {
                disabled = true;
              }
              if (view.view === 'product_data') {
                disabled = true;
              }
            }

            return { ...view, display, disabled };
          });

          setViews(updatedViews);
        }
      } catch (error) {
        handleResponse(error);
      }
    };

    fetchData();
  }, [profile]);

  useEffect(() => {
    const categoryFilter = searchParams.get('cf');
    if (categoryFilter) {
      setCategoryFilter(categoryFilter);
    }
  }, [searchParams]);

  return (
    <div className="w-full">
      <div className={`bg-backgroundCleanBlue border-b border-greySix ${profile?.type !== 1 ? 'mb-6' : ''}`}>
        <div className="relative mx-auto container bg-backgroundCleanBlue">
          <div className="scrollbar-hide w-full overflow-x-scroll px-5" style={{ WebkitOverflowScrolling: 'touch' }}>
            <div className="w-max flex items-center gap-x-2 min-w-full">
              {views.map((profile, index) => {
                if (profile.display && !profile.disabled) {
                  return (
                    <Link
                      key={index}
                      to={`${(isDashboard ? '/dashboard/suppliers/' : '/profile/') + profile.url}${
                        searchParams.get('partner') ? '&' : '?'
                      }${categoryFilter ? `cf=${categoryFilter}` : ''}`}
                      className={`whitespace-nowrap py-4 px-9 transition-all duration-150 ease-in-out font-moskauGrotesk text-sm no-underline border-t-0 border-x-0 border-b-2 ${
                        view === profile.view
                          ? 'text-primaryNewBlue font-bold border-primaryNewBlue'
                          : 'text-greyFour font-medium border-backgroundCleanBlue hover:text-primaryNewBlue hover:border-primaryNewBlue'
                      }`}
                    >
                      {t(profile.label, { partnerName: partner.name })}
                    </Link>
                  );
                }
                if (profile.display && profile.disabled && profile.demo) {
                  return (
                    <div
                      key={index}
                      className="whitespace-nowrap py-4 px-9 text-greyFour font-moskauGrotesk text-sm font-medium border-b-2 border-backgroundCleanBlue hover:text-primaryNewBlue hover:border-primaryNewBlue cursor-pointer"
                    >
                      {t(profile.label, { partnerName: partner.name })}
                    </div>
                  );
                }
                return profile.display && profile.disabled ? (
                  <div
                    key={index}
                    className="whitespace-nowrap py-4 px-9 text-greySix font-moskauGrotesk text-sm font-medium border-b-2 border-backgroundCleanBlue"
                  >
                    {t(profile.label, { partnerName: partner.name })}
                  </div>
                ) : null;
              })}
            </div>
          </div>

          {/* Gradients for scroll indication */}
          <div className="absolute left-0 top-0 h-full w-10 bg-gradient-to-r from-backgroundCleanBlue to-transparent pointer-events-none" />
          <div className="absolute right-0 top-0 h-full w-10 bg-gradient-to-l from-backgroundCleanBlue to-transparent pointer-events-none" />
        </div>
      </div>

      {profile?.type === 1 && (
        <div className="bg-secondaryCreamBg">
          <div className="container mx-auto bg-secondaryCreamBg py-2 my-3.5 px-3">
            <div className="container flex">
              <img src={bug} className="mr-3" alt="warning" />
              <span className="text-xs font-medium text-greyOne self-center">
                {t('SUBSIDIARY_OF_THE_GROUP_HEADER', { group: profile?.parent_company_name, profile: profile?.name })}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Tabs;
