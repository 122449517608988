import React from 'react';

function Tooltip({ text, customClass }) {
  return (
    <div className={`hidden group-hover:block absolute left-14 bg-greyTwo text-white text-sm py-2.5 px-3 rounded z-50 transition-all duration-50 text-nowrap ${customClass}`}>
      <div className=" absolute -left-[4px] bottom-[14px] w-3 h-3 bg-greyTwo rotate-45 rounded-sm" />
      {text}
    </div>
  );
}

export default Tooltip;
