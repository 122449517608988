import React, { useEffect } from 'react';
import link from '../../../assets/ui/link.svg';

function Description({ sector, category, index, description, descriptionRef }) {
  useEffect(() => {
    const div = document.getElementById(`description-${category.id}`);
    if (div) {
      const handleAnimation = (isOpen) => {
        const animation = div.animate(
          [
            {
              maxHeight: isOpen ? '0px' : `${div.scrollHeight}px`,
              overflow: 'hidden',
            },
            {
              maxHeight: isOpen ? `${div.scrollHeight}px` : '0px',
              overflow: 'hidden',
            },
          ],
          {
            duration: 350,
            fill: 'forwards',
            easing: 'ease',
          },
        );

        animation.onfinish = () => {
          div.style.maxHeight = isOpen ? '100%' : '0px';
        };
      };

      if (description &&
          description[index]?.sectorId === sector.level[0].id &&
          description[index]?.id === category.id &&
          div.style.maxHeight === '0px') {
        handleAnimation(true);
      } else if (description[index]?.id !== category.id &&
                 div.style.maxHeight === '100%') {
        handleAnimation(false);
      } else if (description[index]?.sectorId !== sector.level[0].id) {
        div.style.maxHeight = '0px';
      }
    }
  }, [description, index, sector, category]);

  return (
    <div
      ref={descriptionRef}
      id={`description-${category.id}`}
      className={`overflow-hidden ${description &&
        description[index]?.sectorId === sector.level[0].id &&
        description[index]?.id === category.id ? '' : 'max-h-0'}`}
    >
      <div className="mt-6 pb-0">
        <div className="mt-3.5 md:flex">
          {/* Description */}
          {category.description && (
            <div className="text-sm text-greyThree md:mr-6 md:flex-1">
              {category.description}
            </div>
          )}

          {/* Resources */}
          {category.resources && category.resources.length > 0 && (
            <div className="md:w-1/3 mt-6 md:mt-0">
              <div className="flex flex-col gap-1">
                {category.resources.map((resource, idx) => (
                  <a
                    key={idx}
                    href={resource.url}
                    className="flex items-center hover:bg-gray-50 rounded-full transition-colors"
                    target="blank"
                    rel="noreferrer"
                  >
                    <img src={link} alt="Link icon" className="w-5 h-5" />
                    <div className="ml-1.5 text-primaryNewBlue underline font-medium text-sm">
                      {resource.name}
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Description;
