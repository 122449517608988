import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';
import checkDarkBlue from '../../../../assets/ui/check-dark-blue.svg';

/**
 * Dropdown component that renders a customizable dropdown menu.
 *
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the dropdown.
 * @param {function} props.handleChange - The function to handle the change event.
 * @param {Array} props.options - The array of options for the dropdown.
 * @param {string|number} props.value - The current value of the dropdown.
 * @param {boolean} props.showLabel - Show the label inside the dropdown
 *
 * @returns {JSX.Element} The rendered Dropdown component.
 */
function Dropdown({
  name,
  handleChange,
  options,
  value,
  showLabel = true,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const { t } = useTranslation();

  const dropdownRef = useRef(null);
  const contentRef = useRef(null);

  const handleClickDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickElem = (option) => {
    if (JSON.stringify(option) === JSON.stringify(selected)) {
      handleChange('');
      setSelected(null);
    } else {
      handleChange(option?.value);
      setSelected(option);
    }
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value !== selected?.value && options) {
      setSelected(
        options.find((option) => String(option?.value) === String(value)),
      );
    }
  }, [value]);

  useEffect(() => {
    options?.forEach((option) => {
      if (option?.value === value) {
        setSelected(option);
      }
    });
  }, [options]);

  const showLabelClass = showLabel ? 'text-[10px]' : 'hidden';

  return (
    <div ref={dropdownRef} className="relative rounded-sm max-w-60">
      <button
        type="button"
        className="relative max-h-10 h-10 border border-solid rounded-lg border-greySix focus:outline-none focus:ring-2 focus:ring-secondarySkyBlue text-greyFour text-xs px-4 filter-select w-full sm:w-56 flex justify-between items-center"
        onClick={handleClickDropdown}
      >
        <div
          className={`transition-all duration-600 text-greyFour ${
            t(selected?.name) ? showLabelClass : 'text-xs'
          }`}
        >
          {t(name)}
        </div>
        <div className="gap-2.5 text-greyTwo w-44 truncate text-left text-xs">
          {t(selected?.name)}
        </div>
        <img
          src={dropdownArrow}
          className={`${
            isOpen ? 'rotate-180' : ''
          } duration-500 transition-all`}
          alt="Dropdown arrow"
        />
      </button>
      <div
        ref={contentRef}
        className={`absolute top-full w-full mt-1 z-50 transition-all duration-200 overflow-hidden rounded-[6px] bg-white border border-solid overflow-y-auto ${
          isOpen
            ? 'border-blueVeryLight visible'
            : 'border-[#00ffff00] invisible'
        }`}
        style={{ maxHeight: isOpen ? '200px' : '0' }}
      >
        <div className="flex flex-col text-xs">
          {options?.map((option) => (
            <button
              type="button"
              key={option?.name}
              className={`px-4 py-3 w-full flex justify-between text-xs text-left border-0 text-greyTwo bg-white transition-all delay-50 ${
                isOpen ? 'hover:bg-blueVeryLight' : ''
              }`}
              onClick={() => handleClickElem(option)}
              style={{
                backgroundColor:
                  String(option?.value) === String(selected?.value)
                    ? 'bg-blueVeryLight'
                    : '',
              }}
            >
              <div className="w-44 text-left break-words">
                {t(option?.name)}
              </div>
              {option?.value === selected?.value && (
                <img src={checkDarkBlue} className="" alt="Check" width={16} />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
