const URL_TYPE = 1;

const getContactUrl = async (get, partnerId) => {
  try {
    const response = await get(`partner/${partnerId}/contact-informations`);

    if (response) {
      if (response.type === URL_TYPE) {
        return response.url;
      }
      return `mailto:${response.url}`;
    }
    return null;
  } catch (error) {
    throw new Error(error);
  }
};

export default getContactUrl;
