import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useViewport from '../../../hooks/useViewport';
import getLocalLink from '../../../utils/getLocalLink';

function Login({ partner }) {
  const { isMobile } = useViewport();
  const { t } = useTranslation();

  return (
    <section className="container mt-10">
      <div className={`flex flex-wrap mb-5 bg-white rounded-[20px] ${isMobile ? 'mx-0' : ''}`}>
        {/* Left side */}
        <div className="w-full md:w-1/2 p-4 flex items-center">
          <div className="w-full text-center min-[728px]:text-left min-[728px]:leading-10 text-2xl max-md:text-lg font-semibold max-md:pr-[5%]">
            <div className="w-full mx-auto">
              {t('PARTNER_LOGIN_TEXT_4')} {t('PARTNER_LOGIN_TEXT_5')} {partner.name} ? {t('PARTNER_LOGIN_TEXT_6')}
            </div>
          </div>
        </div>

        {/* Right side */}
        <div className="w-full md:w-1/2 p-4 px-0">
          <div className="h-full flex items-center justify-center max-md:border-none">
            <Link
              to={getLocalLink('/login')}
              className="px-6 py-2 rounded-lg bg-primaryNewBlue text-white
                hover:bg-primaryNewBlueHover transition-colors duration-200"
            >
              {t('PARTNER_LOGIN_TEXT_10')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
