import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';

import getUserLocal from '../../../../../utils/getUserLocal';
import getQuestions from '../../../../../utils/getQuestions';
import TableOfContents from './TableOfContents/TableOfContents';
import Category from './Category';
import getVisibility from '../../../../../utils/getVisibility';
import handleResponse from '../../../../../utils/handleResponse';
import QuestionQualitativeFormModal from '../../Modal/Forms/QuestionQualitativeFormModal';
import QuestionQuantitativeFormModal from '../../Modal/Forms/QuestionQuantitativeFormModal';
import DeleteConfirmationModal from '../../Modal/Forms/DeleteConfirmationModal';
import QuestionsCarbonModelFormModal from '../../Modal/Forms/QuestionsCarbonModelFormModal';

function QuestionContainer({ profile, partner, questionType, view, hasWriteAccess, isPublished, setIsPublished }) {
  const [data, setData] = useState();
  const [categories, setCategories] = useState();
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [numberOfAnsweredQuestions, setNumberOfAnsweredQuestions] = useState(0);
  const [progressionBar, setProgressionBar] = useState(0);
  const [summary, setSummary] = useState([]);
  const [itemToDelete, setItemToDelete] = useState();

  const { t } = useTranslation();

  const api = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getAnsweredQuestionsCount = (categories) => {
    let answeredQuestions = 0;
    categories.forEach((category) => {
      category.individualQuestions.forEach((question) => {
        if (question.answer) {
          answeredQuestions += 1;
        }
      });
    });
    return answeredQuestions;
  };

  const fetchData = async () => {
    try {
      const response = await getQuestions(api, profile.id, partner.domain, view, getUserLocal(), questionType, profile.typology_id, partner.id, hasWriteAccess);

      if (api.response.ok) {
        setCategories(response.categories);
        setIsPublished(response.is_published_tab);
        setSummary(response.table_contents);
        const answeredNormal = getAnsweredQuestionsCount(response.table_contents);
        setNumberOfAnsweredQuestions(answeredNormal);
        let totalQuestions = 0;
        response.categories.forEach((category) => {
          totalQuestions += category.category[0].questions.length;
        });
        setNumberOfQuestions(totalQuestions);
        const progressionBar = (answeredNormal * 100) / totalQuestions;
        setProgressionBar(parseInt(progressionBar, 10));
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async () => {
    try {
      await api.del(`/profiles/${profile.id}/content-value/${itemToDelete}`);
      if (api.response.ok) {
        fetchData();
      }
    } catch (error) {
      handleResponse(api.response);
      throw new Error(error);
    }
  };

  return (
    <div className="container py-10 mx-auto px-5">
      <div className="lg:grid grid-cols-12 gap-6">
        <div className="col-span-4 col-start-1">
          <TableOfContents
            hasWriteAccess={hasWriteAccess}
            progressionBar={progressionBar}
            numberOfQuestions={numberOfQuestions}
            numberOfAnsweredQuestions={numberOfAnsweredQuestions}
            summary={summary}
            isPublished={isPublished}
          />
        </div>
        <div className="col-span-8 col-start-5">
          <div className="flex flex-col gap-6">
            {categories && categories.map((category, index) => {
              const show = getVisibility(category, hasWriteAccess, isPublished);

              return (
                show
                && (
                <Category
                  key={index}
                  category={category.category[0]}
                  profile={profile}
                  isPublished={isPublished}
                  partner={partner}
                  view={view}
                  hasWriteAccess={hasWriteAccess}
                  fetchData={fetchData}
                  setData={setData}
                  setItemToDelete={setItemToDelete}
                />
                )
              );
            })}
          </div>
        </div>
        {hasWriteAccess ? (
          <>
            <QuestionQualitativeFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionQuantitativeFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <QuestionsCarbonModelFormModal data={data && data} profileId={profile.id} fetchData={fetchData} />
            <DeleteConfirmationModal modalId="delete-confirmation-modal-questions" handleDeleteFunction={handleDelete} title={t('DELETE_COMFIRMATION_MODAL.TITLE_A')} message={t('DELETE_COMFIRMATION_MODAL.TITLE_B')} />
          </>
        ) : ('')}
      </div>
    </div>
  );
}

export default QuestionContainer;
