import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import Features from '../../../features.json';
import getContactUrl from '../../../utils/getPartnerContactUrl';

function Presentation({ partner, features }) {
  const [contactUrl, setContactUrl] = useState();
  const { t } = useTranslation();
  const { get } = useFetch(process.env.REACT_APP_PARTNER_API);

  const fetchData = async () => {
    const url = await getContactUrl(get, partner.id);
    setContactUrl(url);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="container mx-auto mt-4">
      <div className="mb-5 bg-white rounded-2xl mx-0">
        <div className="p-4 my-auto w-full">
          <div>
            <h2 className="text-3xl font-semibold mb-3 text-primary">
              {partner.presentation_title}
            </h2>
            <div className="text-sm font-normal [&>p]:mb-4 last:[&>p]:mb-0">
              {parse(partner.presentation_content)}
            </div>
            {features.includes(Features.contactInformations) && contactUrl ? (
              <a
                href={contactUrl}
                className="no-underline text-primary hover:text-primary/80 transition-colors"
                target="_blank"
                rel="noreferrer"
              >
                {t('PARTNER_CONTACT_US')}
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Presentation;
