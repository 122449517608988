import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { Input } from '@Openclimat/openclimat-component-library';

import TemplateFormModal from '../../../../Modal/TemplateFormModal';
import useModal from '../../../../../hooks/useModal';
import getUserLocal from '../../../../../utils/getUserLocal';
import handleResponse from '../../../../../utils/handleResponse';

export default function QuestionsActionsLinkFormModal({ data, profileId, fetchData }) {
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm({
    mode: 'onTouched',
  });
  const { close } = useModal();

  const { t } = useTranslation();

  const { post, put, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/profiles/${profileId}/content-list-item`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const linkValue = watch('url');
  const linkTitleValue = watch('urlTitle');

  useEffect(() => {
    reset({
      url: data?.url || '',
      urlTitle: data?.name || '',
    });
  }, [data]);

  const onSubmit = async (formData) => {
    try {
      const finalData = {
        answer_id: data.answerId,
        name: formData.urlTitle,
        type: data.type,
        url: formData.url,
        locale: getUserLocal(),
      };
      if (data?.id) {
        await put(data.id, finalData);
      } else {
        await post(finalData);
      }
      if (response.ok) {
        close();
        fetchData();
      }
    } catch (error) {
      handleResponse(response);
      throw new Error(error);
    }
  };

  return (
    <TemplateFormModal
      modalId="question-actions-link-modal"
      formId="question-actions-link-form"
      title={data?.title}
    >
      <form
        id="question-actions-link-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="p-4 md:p-5 space-y-4">
          <div className="mb-4">
            <label htmlFor="answer" className="block text-base font-medium text-greyOne mb-2.5">
              {data?.info}*
            </label>
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="urlTitle"
              label={t('LINK_TITLE')}
              defaultValue={data?.name}
              errors={errors}
              register={register}
              validationSchema={{
                required: linkValue ? t('LINK _TITLE_REQUIRED') : false,
              }}
            />
          </div>
          <div className="mb-4">
            <Input
              type="text"
              name="url"
              label={t('LINK')}
              errors={errors}
              register={register}
              defaultValue={data?.url}
              validationSchema={{
                required: linkTitleValue ? t('LINK_URL_REQUIRED') : false,
                pattern: {
                  value: /^(ftp|http|https):\/\/[^ "]+$/,
                  message: t('LINK_URL_INVALID'),
                },
              }}
            />
          </div>
        </div>
      </form>
    </TemplateFormModal>
  );
}
