import React, { useEffect, useState } from 'react';
import { useFetch } from 'use-http';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useViewport from '../../../hooks/useViewport';

import handleResponse from '../../../utils/handleResponse';
import getPartnerCategories from '../../../utils/getPartnerCategories';
import getSectorFromParam from './getSectorFromParam';
import CategoryCard from './CategoryCard';
import findParentCategory from '../../../utils/findParentCategory';
import CategoryCardMobile from './CategoryCardMobile';

function CategoryFilter({ partner, setFilteredCategory }) {
  const [allLevels, setAllLevels] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selected, setSelected] = useState([]);
  const [description, setDescription] = useState([]);
  const [loading, setLoading] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const QUERY_NAME = 'cf';

  const api = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/categories`, {
    cachePolicy: 'no-cache',
  });

  const getCategories = async () => {
    try {
      const result = await getPartnerCategories(api.get);
      if (result) {
        if (result[0].level[0].categories) {
          setSectors([result[0]]);
          setAllLevels(result);
        } else {
          setSectors([result[1]]);
          setAllLevels(result.filter((item, index) => index !== 0));
        }
      }
    } catch (error) {
      throw error;
    } finally {
      handleResponse(api.response);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (allLevels.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const categoryId = params.get(QUERY_NAME);

      if (categoryId) {
        getSectorFromParam(
          QUERY_NAME,
          allLevels,
          setSectors,
          setDescription,
          setSelected,
          setFilteredCategory,
          findParentCategory,
        );
      }
    }
  }, [allLevels]);

  return (
    <div className={`container ${!isMobile ? 'mb-5' : 'mb-4'} mt-10`}>
      <div id="category-filter" className="relative -top-[100px]" />

      <h3 className="text-2xl font-semibold mb-4">
        {t('CATEGORIES_FILTER_HEADER')}
      </h3>

      <div className="p-6 bg-white rounded-lg">
        {/* Version Desktop */}
        <div className="hidden md:block">
          {allLevels?.map((sector, index) => (
            <CategoryCard
              key={sector.level[0].id}
              sector={sector}
              index={index}
              sectors={sectors}
              loading={loading}
              setLoading={setLoading}
              QUERY_NAME={QUERY_NAME}
              searchParams={searchParams}
              setFilteredCategory={setFilteredCategory}
              allLevels={allLevels}
              setDescription={setDescription}
              setSearchParams={setSearchParams}
              setSelected={setSelected}
              description={description}
              setSectors={setSectors}
              selected={selected}
            />
          ))}
        </div>

        {/* Version Mobile */}
        <div className="md:hidden">
          {sectors?.map((sector, index) => (
            <CategoryCardMobile
              key={sector.level[0].id}
              sector={sector}
              index={index}
              sectors={sectors}
              setSelected={setSelected}
              description={description}
              setDescription={setDescription}
              setFilteredCategory={setFilteredCategory}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              allLevels={allLevels}
              setSectors={setSectors}
              QUERY_NAME={QUERY_NAME}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default CategoryFilter;
