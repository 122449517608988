import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../../../../../hooks/useNotifications';
import NotificationPoint from '../NotificationPoint';

function Category({ profileId, showCategoryNotes, categories, handleShowCategoryNotes, loading }) {
  const { hasUnreadNotifications } = useNotifications(profileId);
  const { t } = useTranslation();

  return (
    <>
      <div className="text-greyThree">
        {t('NOTES.SUBTITLE_A')}
      </div>
      <div className="mt-6 flex flex-col gap-2.5">
        {loading && (
          <div className="bg-[#FDFDFD] border border-solid border-greySeven p-[14px] rounded">
            <div className="bg-greySeven animate-pulse h-12 rounded" />
          </div>
        )}
        {!showCategoryNotes && !loading && categories?.map((category) => (
          <button
            key={category.id}
            type="button"
            className="relative bg-[#FDFDFD] border border-solid border-greySeven p-[14px] text-left font-moskauGrotesk rounded hover:border-secondarySkyBlue"
            onClick={() => handleShowCategoryNotes(category)}
          >
            <div className="text-greyThree text-sm">
              {category.category_name}
            </div>
            <div className="text-sm text-primaryNewBlue font-semibold mt-1.5">
              {category.notes_count} {t('NOTES.NOTES')}
            </div>
            {hasUnreadNotifications(category.id) && (
            <div className="absolute right-[6px] top-[6px]">
              <NotificationPoint />
            </div>
            )}
          </button>
        ))}
      </div>
    </>
  );
}

export default Category;
