import React from 'react';
import { useTranslation } from 'react-i18next';

import dropdownArrow from '../../../../assets/ui/caret-down-s.svg';

function FilterDropdownButton({ isOpen, onToggle }) {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className="relative max-h-10 h-10 border border-solid rounded-lg border-greySix text-greyFour text-xs px-4 filter-select w-full sm:w-56 flex justify-between items-center"
      onClick={onToggle}
    >
      <div className="transition-all duration-600 flex flex-col items-start">
        <div className="transition-all duration-600 text-greyFour">
          {t('PARTNER_DASHBOARD.STATISTICS.FILTER.PLACEHOLDER')}
        </div>
      </div>
      <img
        src={dropdownArrow}
        className={`${isOpen ? 'rotate-180' : ''} duration-500 transition-all`}
        alt="Dropdown arrow"
      />
    </button>
  );
}

export default FilterDropdownButton;
