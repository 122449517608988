import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getHexColorWithOpacity from '../../utils/getHexColorWithOpacity';
import LogoGrey from '../../assets/logo-grey.svg';
import burgerIcon from '../../assets/ui/burger-icon.svg';
import Authenticated from './Authenticated/Authenticated';
import getLocalLink from '../../utils/getLocalLink';
import Search from '../Features/Search/Search';
import Features from '../../features.json';

// Composant pour le bloc avec logo OpenClimat
function OpenClimatBlock({ t }) {
  return (
    <ul className="flex items-center">
      <li className="flex items-center px-2 py-2 text-greyFour">
        <span className="text-sm font-normal">{t('WITH')}&nbsp;</span>
        <img src={LogoGrey} alt="openclimat" className="h-5" />
      </li>
    </ul>
  );
}

function Header({ partner, session, features, searchBar, showHeader = true }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const search = searchBar && features.includes(Features.bigSearch) && <Search partner={partner} navbarTemplate />;

  // Fermer le menu mobile lors du redimensionnement
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <nav className="w-full py-2 sticky top-0 bg-white z-50">
        <div className="container mx-auto">
          <div className="flex flex-col">
            {/* Barre principale */}
            <div className="flex items-center justify-between px-4 h-14">
              {/* Logo */}
              <a href={getLocalLink('/')} className="flex items-center">
                {partner.logo_header ? (
                  <img
                    src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_header}`}
                    alt={partner.name}
                    className="h-8 w-auto object-contain"
                  />
                ) : (
                  <span className="text-lg font-medium">{partner.name}</span>
                )}
              </a>

              {/* Barre de recherche */}
              <div className="max-lg:hidden flex items-center">
                {search}
              </div>

              {/* Bouton menu mobile */}
              <button
                type="button"
                className="lg:hidden p-2 hover:bg-greyEight rounded-lg transition-colors"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <img src={burgerIcon} alt="Menu" className="w-6 h-6" />
              </button>

              {/* Menu desktop */}
              <div className="hidden lg:block">
                {session ? (
                  <Authenticated session={session} partner={partner} />
                ) : (
                  <OpenClimatBlock t={t} />
                )}
              </div>
            </div>

            {/* Menu mobile */}
            <div
              className={`flex justify-center transform transition-all duration-300 ease-in-out lg:hidden
                ${isMenuOpen ? 'max-h-48 opacity-100' : 'max-h-0 opacity-0'}`}
            >
              <div className="px-4 py-2 flex flex-col gap-y-2 items-center">
                {search}
                {session ? (
                  <Authenticated session={session} partner={partner} />
                ) : (
                  <OpenClimatBlock t={t} />
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Header avec gradient */}
      {showHeader && (
        <header
          className="py-8 z-0"
          style={{
            backgroundImage: `linear-gradient(89.51deg, 
              ${getHexColorWithOpacity(partner?.primary_color, 0.043)},
              ${getHexColorWithOpacity(partner?.primary_color, 0.4058)},
              ${getHexColorWithOpacity(partner?.primary_color, 0.9957)})`,
          }}
        >
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center gap-8">
              <div className="w-full md:w-1/2 flex justify-center">
                <img
                  src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_banner ?? partner.logo_header}`}
                  alt={`Logo ${partner.name}`}
                  className="max-h-32 object-contain"
                />
              </div>

              {partner?.header_title && partner?.header_description && (
                <div className="w-full md:w-1/2 text-center text-greyOne">
                  <h1 className="text-4xl font-bold mb-5">
                    {partner.header_title}
                  </h1>
                  <p className="text-lg">
                    {partner.header_description}
                  </p>
                </div>
              )}
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Header;
