import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import LogoWhite from '../../assets/logo-white.svg';
import Linkedin from '../../assets/linkedin.svg';
import Twitter from '../../assets/twitter.svg';
import XLetter from '../../assets/ui/x.svg';

import './Footer.scss';

import Disclaimer from '../Features/Disclaimer/Disclaimer';
import Features from '../../features.json';
import getContactUrl from '../../utils/getPartnerContactUrl';

function Footer({ partner, features }) {
  const [contactUrl, setContactUrl] = useState();

  const { t } = useTranslation();

  const { get } = useFetch(process.env.REACT_APP_PARTNER_API);

  const footerLinks = [
    { title: 'FOOTER_TITLE_4',
      sublinks:
    [
      { href: features.includes(Features.contactInformations) && contactUrl ? contactUrl : null, label: `${t('CONTACT_PARTNER')} ${partner.name}`, enable: features.includes(Features.contactInformations) },
      { href: 'https://www.openclimat.com/fr/contact', label: t('CONTACT_OPEN_CLIMAT'), enable: true },
    ] },
  ];

  const disclaimer = features.includes(Features.disclaimer) && <Disclaimer partner={partner} />;

  const fetchData = async () => {
    const url = await getContactUrl(get, partner.id);
    setContactUrl(url);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <footer className="w-full bg-primaryNewBlue px-6">
      <div className="container pt-12 mx-auto">
        <div className="grid grid-cols-12 pt-2 pb-6">
          <div className="col-span-12 lg:col-span-3 mb-4">
            <div className="flex mb-6 flex-col w-fit">
              <div className="self-center">
                <img src={`${process.env.REACT_APP_FILES_DOMAIN}${partner.logo_header}`} className="logo-footer" alt={`Logo ${partner.name}`} loading="lazy" />
              </div>
              <div className="self-center px-3">
                <img src={XLetter} alt="X" height={16} />
              </div>
              <div className="self-center">
                <a href="https://www.openclimat.com">
                  <img src={LogoWhite} className="logo-footer-oc" alt="Logo" loading="lazy" />
                </a>
              </div>
            </div>
            <a href="https://twitter.com/OpenClimat_" className="inline-block mx-2 md:ms-0" target="_blank" title="Twitter" rel="nofollow noreferrer">
              <img src={Twitter} alt="Twitter" style={{ width: '18px' }} />
            </a>
            <a href="https://www.linkedin.com/company/open-climat/" className="inline-block mx-2 md:ms-0" target="_blank" title="Linkedin" rel="nofollow noreferrer">
              <img src={Linkedin} alt="Linkedin" style={{ width: '18px' }} />
            </a>
          </div>
          {footerLinks.map((col, index) => (
            <div key={index} className="col-span-12 lg:col-span-3 md:pt-2 text-white">
              <h6
                className={`text-sm ${!partner && 'violet-color'}`}
              >
                {t(col.title)}
              </h6>
              <ul className="list-unstyled">
                {col.sublinks.map((link, index) => (
                  link.enable
                  && (
                  <li key={index}>
                    {link.label === 'BLOG'
                      ? <a href={link.href} className="text-white text-xs footer-link hover:text-white" target="_blank" rel="noreferrer">{link.label}</a>
                      : <a href={link.href} className="text-white text-xs footer-link hover:text-white" target="_blank" rel="noreferrer">{link.label}</a>}
                  </li>
                  )
                ))}
              </ul>
            </div>
          ))}
          {disclaimer}
        </div>
        <div className="grid grid-cols-12 mt-2">
          <div className="col-span-12 border-top">
            <p className="text-center my-6 opacity-25 text-sm text-white">© 2025 OpenClimat</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
