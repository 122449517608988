import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFetch } from 'use-http';
import handleResponse from '../../../../utils/handleResponse';
import Dropdown from './Dropdown';
import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function Status({ partner }) {
  const { setLoading } = useContext(TableLoadingContext);
  const [value, setValue] = useState('');
  const [profileStatusList, setProfileStatusList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API);

  const handleStatusFilter = (value) => {
    setLoading(true);
    if (value === '') {
      searchParams.delete('status_filter');
      searchParams.set('p', 0);
    } else {
      searchParams.set('status_filter', value);
      searchParams.set('p', 0);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const getProfileStatusList = async () => {
      try {
        await get(`/partner/${partner.id}/profile-status`);
        if (response.ok) {
          setProfileStatusList(response.data.map((status) => ({
            name: status.name,
            value: status.id,
          })));
        }
      } catch (error) {
        handleResponse(response);
      }
    };
    getProfileStatusList();
  }, [partner.id, get, response]);

  useEffect(() => {
    if (searchParams.get('status_filter')) {
      setValue(searchParams.get('status_filter'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name="STATUS"
      handleChange={handleStatusFilter}
      options={profileStatusList}
      value={value}
    />
  );
}

export default Status;
