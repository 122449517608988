import React from 'react';
import { useTranslation } from 'react-i18next';
import checkWhite from '../../../../assets/ui/check-white.svg';
import leftArrow from '../../../../assets/ui/arrow-left-greyFour.svg';
import CategoryButton from './CategoryButton';

function FilterDropdownContent({
  isOpen,
  navigationPath,
  currentCategories,
  selectedCategoryId,
  onBack,
  onSelectAll,
  onCategorySelect,
  currentLevel,
  levels,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`absolute -top-1 w-full mt-1 z-50 transition-all duration-200 overflow-hidden rounded-[6px] bg-white border border-solid border-greySix ${
        isOpen ? 'visible max-h-[400px]' : 'invisible max-h-0'
      } py-2 px-3`}
    >
      <div className="flex flex-col text-xs">

        {navigationPath.length > 0 && (
          <div className="py-3 pl-2.5 flex items-center border-b border-solid border-x-0 border-t-0 border-greySeven">
            <button
              type="button"
              onClick={onBack}
              className="mr-2.5 border-none bg-white"
            >
              <img src={leftArrow} alt="Back" width={20} />
            </button>
            <span className="text-primaryNewBlue font-moskauGrotesk font-medium text-sm">
              {navigationPath[navigationPath.length - 1].categoryName}
            </span>
          </div>
        )}

        {navigationPath.length > 0 ? (
          <button
            type="button"
            className="px-4 py-3 w-full flex justify-between items-center text-xs text-left border-b border-t-0 border-x-0 border-solid border-b-greySeven text-greyTwo bg-white hover:bg-greyEight"
            onClick={onSelectAll}
          >
            <div className="w-44 text-left text-sm text-primaryNewBlue font-moskauGrotesk font-medium">
              {t('PARTNER_DASHBOARD.STATISTICS.FILTER.ENTIRE_CATEGORY')}
            </div>
            <div
              className={`min-w-4 min-h-4 flex items-center justify-center rounded-full ${
                selectedCategoryId === navigationPath[navigationPath.length - 1].categoryId
                  ? 'bg-primaryNewBlue'
                  : ' border border-solid border-greyFour'
              }`}
            >
              {selectedCategoryId === navigationPath[navigationPath.length - 1].categoryId && (
              <img src={checkWhite} alt="Check" width={8} />
              )}
            </div>
          </button>
        ) : (
          <div className="px-4 py-3 w-full flex justify-between border-b border-t-0 border-x-0 border-solid border-b-greySeven bg-white text-sm text-greyFour font-moskauGrotesk font-base">
            {t('PARTNER_DASHBOARD.STATISTICS.FILTER.CATEGORY_CHOOSE')}
          </div>
        )}

        {/* Categories list */}
        {currentCategories.map((category) => (
          <CategoryButton
            key={category.id}
            category={category}
            currentLevel={currentLevel}
            levels={levels}
            selectedCategoryId={selectedCategoryId}
            onClick={() => onCategorySelect(category)}
          />
        ))}
      </div>
    </div>
  );
}

export default FilterDropdownContent;
