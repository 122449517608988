import React from 'react';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../../assets/ui/search.svg';

import Results from '../Results/Results';

function Medium({ profiles, query, setQuery, handleClick }) {
  const { t } = useTranslation();

  return (
    <div className="relative max-w-[800px] min-w-[270px]  mx-auto z-10  md:p-0">
      <div className="relative">
        <input
          type="text"
          placeholder={t('SEARCH_COMPANY_PARTNER')}
          className="w-[272px] md:w-[438px] h-10 rounded-xl px-[18px] py-[11px] pl-[14px] pr-12 text-sm
            bg-greyEight border border-solid border-greySix
            text-greyFour placeholder-greyFour placeholder:font-normal
            focus:outline-none focus:ring-0 focus:border-secondarySkyBlue"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
        />
        <img
          src={searchIcon}
          alt="Search"
          className="absolute right-4 top-1/2 -translate-y-1/2 w-5 h-5 pointer-events-none"
        />
      </div>
      {query.length > 0 && <Results profiles={profiles} handleClick={handleClick} />}
    </div>
  );
}

export default Medium;
