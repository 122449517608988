import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import resolveConfig from 'tailwindcss/resolveConfig';

import circleQuestion from '../../../../assets/ui/circle-question.svg';
import circleQuestionScope3 from '../../../../assets/ui/circle-question-scope-3.svg';
import circleQuestionGrey from '../../../../assets/ui/circle-question-grey.svg';
import crossIconGrey from '../../../../assets/ui/cross-icon-grey.svg';
import calendarCrossBlue from '../../../../assets/ui/calendar-cross-blue.svg';
import calendarCheckBlue from '../../../../assets/ui/calendar-check-blue.svg';
import questionMarkCircled from '../../../../assets/ui/question-mark-circled.svg';

import tailwindConfig from '../../../../tailwind.config';
import getCarbonFootprintDistribution from '../../../../utils/getCarbonFootprintDistribution';
import getUserLocal from '../../../../utils/getUserLocal';
import CarbonFootprintDistributionPie from './Chart/CarbonFootprintDistributionPie';
import maturityInfo from '../../../../utils/maturityInfo';
import getCarbonFootprintLevel from '../../../../utils/getCarbonFootprintLevel';
import AdvancementCircle from '../MaturityScale/AdvancementCircle';
import carbonFootprintChartBuilder from '../../../../utils/carbonFootprintChartBuilder';
import carbonFootprintFlagChartBuilder from '../../../../utils/carbonFootprintFlagCharBuilder';

function CarbonFootprintDistribution({ profile }) {
  const [comment, setComment] = useState();
  const [carbonFootprint, setCarbonFootprint] = useState();
  const [carbonFootprintDistribution, setCarbonFootprintDistribution] = useState();
  const [fromParentCompany, setFromParentCompany] = useState();
  const [carbonFootprintYear, setCarbonFootprintYear] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartDataFiltered, setChartDataFiltered] = useState([]);
  const [totalCarbonFootprintValue, setTotalCarbonFootprintValue] = useState(0);
  const [formattedTotalCarbonFootprintValue, setFormattedTotalCarbonFootprintValue] = useState('');
  const [isPmeWithCommitment, setIsPmeWithCommitment] = useState(false);
  const [commitmentDate, setCommitmentDate] = useState(null);
  const [plannedPublicationDate, setPlannedPublicationDate] = useState(null);
  const [isPlannedPublicationDateValid, setIsPlannedPublicationDateValid] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [isControledPmeWithoutCarbonFootprint] = useState(profile.is_managed && carbonFootprintDistribution?.length > 0 && profile.type === 2);
  const [flagData, setFlagData] = useState();
  const [currentCharData, setCurrentCharData] = useState([]);
  const [isFlagData, setIsFlagData] = useState(false);

  const { t, i18n } = useTranslation();
  const fullConfig = resolveConfig(tailwindConfig);

  const toggleTable = (e) => {
    e.preventDefault();
    setDisplayTable(!displayTable);
    setIsFlagData(false);
    if (isFlagData) {
      setCurrentCharData(chartDataFiltered);
    }
  };

  const getCarbonFootprintComment = (data) => {
    if (data) {
      if ((data.carbon_footprint[0] && data.carbon_footprint[1]) || data.carbon_footprint_distribution.length > 0) {
        return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.ONE');
      }
      if (data.carbon_footprint[0] || data.carbon_footprint[1]) {
        return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.TWO');
      }
      if (isPmeWithCommitment) {
        return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.THREE');
      }
    }
    return t('CARBON_FOOTPRINT_DISTRIBUTION.COMMENT.FOUR');
  };

  useEffect(() => {
    getCarbonFootprintDistribution(profile.id, getUserLocal(), profile.type, profile.reporting_id).then((response) => {
      let totalCarbon;
      if ((response.carbon_footprint[0] && response.carbon_footprint[1]) && response.carbon_footprint.reduce((acc, cur) => acc + cur) < 1) {
        totalCarbon = response.carbon_footprint ? response.carbon_footprint.reduce((acc, cur) => acc + cur).toFixed(3) : 0;
      } else {
        totalCarbon = (response.carbon_footprint[0] && response.carbon_footprint[1]) ? response.carbon_footprint.reduce((acc, cur) => acc + cur).toFixed(1) : 0;
      }
      setCarbonFootprint(response.carbon_footprint);
      setCarbonFootprintDistribution(response.carbon_footprint_distribution);
      setFromParentCompany(response.from_parent_company);
      setCarbonFootprintYear(response.carbon_mix_balance_year);
      setTotalCarbonFootprintValue(response.carbon_footprint[0] + response.carbon_footprint[1]);

      const [data, dataFiltered] = carbonFootprintChartBuilder(totalCarbon, response.carbon_footprint, response.carbon_footprint_distribution, isControledPmeWithoutCarbonFootprint, t);

      if (response.emission_flag_relative) {
        const flagChartData = carbonFootprintFlagChartBuilder(totalCarbon, response.emission_flag_relative, t);

        setFlagData(flagChartData);
      } else {
        setFlagData(null);
        setIsFlagData(false);
      }

      setChartData(data);
      setChartDataFiltered(dataFiltered);
      setCurrentCharData(dataFiltered);
      setIsPmeWithCommitment(profile.type === 2 && response?.commitment_date && response?.planned_publication_date);

      // if the planned_publication_date is not passed then IsPlannedPublicationDateValid is true
      setIsPlannedPublicationDateValid(new Date(response?.planned_publication_date) > new Date());
      setPlannedPublicationDate(new Date(response?.planned_publication_date).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', {
        month: 'long',
        year: 'numeric',
      }));
      setCommitmentDate(new Date(response?.commitment_date).toLocaleString(i18n.language === 'fr' ? 'fr-FR' : 'en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }));

      setComment(getCarbonFootprintComment(response));

      let formattedValue = '';

      const emissionsScope12 = response.carbon_footprint[0] ? parseFloat(response.carbon_footprint[0]) : 0;
      const emissionsScope3 = response.carbon_footprint[1] ? parseFloat(response.carbon_footprint[1]) : 0;
      const totalEmissionsKt = emissionsScope12 + emissionsScope3;

      if (totalEmissionsKt < 1) {
        formattedValue = `${(totalEmissionsKt * 1000).toFixed(0)} t`;
      } else if (totalEmissionsKt < 10) {
        formattedValue = `${totalEmissionsKt.toFixed(2)} kt`;
      } else if (totalEmissionsKt < 100) {
        formattedValue = `${totalEmissionsKt.toFixed(1)} kt`;
      } else if (totalEmissionsKt < 1000) {
        formattedValue = `${totalEmissionsKt.toFixed(0)} kt`;
      } else if (totalEmissionsKt < 10000) {
        formattedValue = `${(totalEmissionsKt / 1000).toFixed(2)} Mt`;
      } else {
        formattedValue = `${(totalEmissionsKt / 1000).toFixed(1)} Mt`;
      }

      setFormattedTotalCarbonFootprintValue(formattedValue);
    });
  }, [profile]);

  const blocInfo = maturityInfo.bilanCarbone;
  const levelInfo = blocInfo.levels[getCarbonFootprintLevel(carbonFootprint, isPlannedPublicationDateValid)];

  const handleChartDataButton = (e, data, isFlagData) => {
    e.preventDefault();
    setCurrentCharData(data);
    setIsFlagData(isFlagData);
    if (isFlagData) {
      setDisplayTable(false);
    }
  };

  return (
    <div className="mb-4 border border-solid border-greySix rounded">
      <div className={`${profile.publishing?.actions.includes(getUserLocal()) ? 'rounded-t' : 'rounded'} p-6 bg-white flex flex-col`}>
        <h3 className="text-greyOne text-base align-middle justify-between items-center me-0 md:mr-4 flex">
          <span className="flex">
            {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_3')}
          </span>
          <div className="flex gap-4">
            {((profile.type === 1 && !profile.is_managed) || (profile.type === 1 && fromParentCompany))
              ? (
                <div className="text-greyFive font-normal text-sm flex items-center">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_2')} {profile.parent_company_name}
                </div>
              ) : null}
          </div>
        </h3>
        <div className="p-4 border border-solid border-greySix bg-backgroundCleanBlue mt-4 mb-6 rounded-sm text-sm text-primaryNewBlue font-medium flex items-center gap-2">
          <AdvancementCircle level={levelInfo.level} bgColor={fullConfig.theme.colors.backgroundCleanBlue} />
          {comment}
        </div>
        {(carbonFootprint && (carbonFootprint[0] || carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint) ? (
          <div className={`text-sm text-greyFive me-0 me-md-4 flex gap-4 ${((profile.type === 1 && !profile.is_managed) || fromParentCompany) ? 'mb-3' : 'mb-6'}`}>
            {flagData ? (
              <>
                <button
                  type="button"
                  className={`${isFlagData ? 'px-3 py-1.5 bg-primaryNewBlue bg-opacity-[0.05] hover:bg-opacity-[0.07] rounded-[5px] border-0 text-greyFour' : 'text-primaryNewBlue font-medium border-0 bg-transparent px-0'}`}
                  onClick={(e) => handleChartDataButton(e, chartDataFiltered, false)}
                >
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_28')}
                </button>
                <button
                  type="button"
                  className={`${isFlagData ? 'text-primaryNewBlue font-medium border-0 bg-transparent px-0' : 'px-3 py-1.5 bg-primaryNewBlue bg-opacity-[0.05] hover:bg-opacity-[0.07] rounded-[5px] border-0 text-greyFour '}`}
                  onClick={(e) => handleChartDataButton(e, flagData, true)}
                >
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_27')}
                </button>
              </>
            ) : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_9')}
          </div>
        ) : null}
        <div className="xl:grid grid-cols-12 mt-4">
          <div className="col-span-4">
            <div className="carbon-footprint-chart-container h-64 overflow-visible py-3">
              <CarbonFootprintDistributionPie
                totalCarbonFootprintValue={totalCarbonFootprintValue}
                formattedTotalCarbonFootprintValue={formattedTotalCarbonFootprintValue}
                carbonFootprintYear={carbonFootprintYear}
                data={currentCharData}
                scope12={carbonFootprint && carbonFootprint[0]}
                scope3={carbonFootprint && carbonFootprint[1]}
              />
            </div>
          </div>
          {((carbonFootprint && !isFlagData && (carbonFootprint[0] && carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint && !isFlagData)) ? (
            <div className="col-span-8 my-auto xl:grid grid-cols-12 xl:ps-5 pe-0 text-sm">
              <div className="flex mt-6 xl:hidden gap-1">
                <div className="xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 flex-nowrap bg-[#21AFFF1A] text-[#21AFFF] rounded-full relative">
                  <div>
                    Scope 1&2
                  </div>
                  <div className="group">
                    <img src={circleQuestion} width="auto" height="auto" alt="circle question" className="mt-0.5" />
                    <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-[160px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                    </div>
                  </div>
                </div>
                <div className="xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 bg-[#000D850D] text-[#000D85AD] rounded-full relative">
                  <div>
                    Scope 3
                  </div>
                  <div className="group">
                    <img src={circleQuestionScope3} width="auto" height="auto" alt="circle question" className="mt-0.5" />
                    <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 right-0 md:left-[160px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-8 xl:px-0 flex mt-6 xl:mt-0">
                <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: '#21AFFF' }} />
                <div className="self-center ms-3 pt-1 text-greyOne">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10')}
                </div>
              </div>
              <div className="col-span-4 xl:flex hidden border-solid border-r-0 border-y-0 border-l border-greySix relative">
                <div className="ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex flex-nowrap bg-[#21AFFF1A] rounded-full">
                  <div className="text-[#21AFFF]">
                    Scope 1&2
                  </div>
                  <div className="group self-center">
                    <img className="ms-1 mb-0.5" src={circleQuestion} width="auto" height="auto" alt="circle question" />
                    <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-full z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-8 pe-0 md:ps-0">
                {(chartDataFiltered) ? (
                  chartDataFiltered.filter((item) => parseInt(item.scope, 10) === 3).map((item, index) => (
                    <div key={index} className="flex mt-6">
                      <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: item.color }} />
                      <div className="self-center ms-3 pt-1 text-greyOne">
                        {t(item.id)}
                      </div>
                    </div>
                  ))
                ) : (
                  ''
                )}
              </div>
              <div className="col-span-4 xl:flex hidden mt-6 pe-0 border-solid border-r-0 border-y-0 border-l border-greySix">
                <div className="ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex bg-[#000D850D] text-[#000D85AD] rounded-full relative">
                  <div>
                    Scope 3
                  </div>
                  <div className="group self-center">
                    <img className="ms-1 mb-0.5" width="auto" height="auto" src={circleQuestionScope3} alt="circle question scope 3" />
                    <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-[140px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {((carbonFootprint && isFlagData && (carbonFootprint[0] && carbonFootprint[1])) || (isControledPmeWithoutCarbonFootprint && isFlagData)) ? (
            <div className="col-span-8 xl:my-auto xl:ps-5 mt-6 text-sm">
              <div className="flex">
                <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex bg-greyFour" />
                <div className="self-center ms-3 pt-1 text-greyOne flex gap-1 relative items-center">
                  <div className="pt-0.5">
                    {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_25')}
                  </div>
                  <div className="inline-block align-middle group">
                    <img src={questionMarkCircled} alt="Question Mark" />
                    <div className="absolute top-full invisible group-hover:visible max-w-44 min-w-44 z-10 py-2 px-3 text-xs text-white font-normal font-moskauGrotesk bg-greyTwo rounded-sm shadow-lg ml-6 -my-[27px]">
                      <div className="absolute -left-[4px] top-[10px] w-3 h-3 bg-greyTwo rotate-45 rounded-sm" />
                      {t('CLIMATE_ACTIONS.FLAG_DEFINITION')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-6 xl:mt-7">
                <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex bg-greySix" />
                <div className="self-center ms-3 pt-1 text-greyOne">
                  {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_26')}
                </div>
              </div>
            </div>
          ) : null}
          {(carbonFootprint && (!carbonFootprint[0] && !carbonFootprint[1]) && carbonFootprintDistribution?.length === 0) ? (
            <div className="xl:col-span-8 flex xl:ps-5 mt-4 xl:mt-0 items-center mr-6">
              <div className="w-full flex items-center justify-center bg-greyEight py-6 px-2 rounded">
                <div className="text-center flex items-center justify-center gap-3">
                  {isPmeWithCommitment ? (
                    <>
                      <div className={`${isPlannedPublicationDateValid ? 'bg-greenLightTwo' : 'bg-greyLightFive'} p-3 rounded-full`}>
                        <img
                          src={isPlannedPublicationDateValid ? calendarCheckBlue : calendarCrossBlue}
                          alt={isPlannedPublicationDateValid ? 'calendar check blue' : 'calendar cross blue'}
                          height={32}
                          width={32}
                        />
                      </div>
                      {isPmeWithCommitment && (
                        <div className="flex flex-col gap-1 text-left">
                          <span className="text-sm text-greyThree">{t('CARBON_FOORPRINT_DISTRIBUTION_EXPECTED_P_DATE')}</span>
                          <span className="text-sm font-semibold text-greyOne">
                            {isPlannedPublicationDateValid ? (
                              t('CARBON_FOORPRINT_DISTRIBUTION_BEFORE').concat(plannedPublicationDate)
                            ) : (
                              plannedPublicationDate.charAt(0).toUpperCase() + plannedPublicationDate.slice(1)
                            )}
                          </span>
                          <span className="text-xs text-greyFour">
                            {t('CARBON_FOORPRINT_COMMITMENT').concat(commitmentDate)}
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="bg-greyLightFive p-3 rounded-full">
                        <img src={crossIconGrey} alt="cross icon grey" height={32} width={32} />
                      </div>
                      <div className="text-sm text-greyThree mt-2">
                        {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : null }
          {(carbonFootprint && !isControledPmeWithoutCarbonFootprint && !isFlagData && ((carbonFootprint[0] || carbonFootprint[1]) && !(carbonFootprint[0] && carbonFootprint[1]))) ? (
            <div className="col-span-8 my-auto xl:grid grid-cols-12 xl:ps-5 pe-0 text-sm">
              <div className="flex mt-6 xl:hidden gap-1">
                <div className={`xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 flex-nowrap rounded-full ${carbonFootprint[0] ? 'bg-[#21AFFF1A] text-[#21AFFF]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                  <div>
                    Scope 1&2
                  </div>
                  <img src={carbonFootprint[0] ? circleQuestion : circleQuestionGrey} width="auto" height="auto" alt="circle question" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')} />
                </div>
                <div className={`xl:ms-6 self-center text-[13px] px-3 py-1.5 flex gap-1 rounded-full ${carbonFootprint[1] ? 'bg-[#000D850D] text-[#000D85AD]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                  <div>
                    Scope 3
                  </div>
                  <img src={carbonFootprint[1] ? circleQuestionScope3 : circleQuestionGrey} width="auto" height="auto" alt="circle question scope 3" data-bs-toggle="tooltip" data-bs-placement="right" title={t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')} />
                </div>
              </div>
              <div className="col-span-8 xl:px-0 flex mt-6 xl:mt-0">
                <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: carbonFootprint[0] ? '#21AFFF' : '#F0F1F5' }} />
                <div className={`self-center ms-3 pt-1 ${carbonFootprint[0] ? 'text-greyOne' : 'text-greyFive'}`}>
                  {carbonFootprint[0] ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_10') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                </div>
              </div>
              <div className="col-span-4 xl:flex hidden border-solid border-r-0 border-y-0 border-l border-greySix relative">
                <div className={`ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex flex-nowrap rounded-full ${carbonFootprint[0] ? 'bg-[#21AFFF1A] text-[#21AFFF]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                  <div>
                    Scope 1&2
                  </div>
                  <div className="group self-center">
                    <img className="ms-1 mb-0.5" src={circleQuestion} width="auto" height="auto" alt="circle question" />
                    <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-full z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_13')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-8 pe-0 md:ps-0">
                <div className="flex mt-6">
                  <div className="self-center h-5 w-5 min-h-5 min-w-5 rounded-full text-center text-white text-base font-medium flex" style={{ backgroundColor: carbonFootprint[1] ? '#000D85' : '#F0F1F5' }} />
                  <div className={`self-center ms-3 pt-1 ${carbonFootprint[1] ? 'text-greyOne' : 'text-greyFive'}`}>
                    {carbonFootprint[1] ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_12') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_16')}
                  </div>
                </div>
              </div>
              <div className="col-span-4 xl:flex hidden mt-6 pe-0 border-solid border-r-0 border-y-0 border-l border-greySix">
                <div className={`ms-1 md:ms-6 self-center text-[13px] px-3 py-1.5 flex rounded-full relative ${carbonFootprint[1] ? 'bg-[#000D850D] text-[#000D85AD]' : 'bg-[#9E9E9E0D] text-greyFive'}`}>
                  <div>
                    Scope 3
                  </div>
                  <div className="group self-center">
                    <img className="ms-1 mb-0.5" width="auto" height="auto" src={circleQuestionScope3} alt="circle question scope 3" />
                    <div className="sm:w-auto invisible group-hover:visible absolute md:top-full min-w-64 md:left-[140px] z-10 p-2 text-xs text-white font-normal font-moskauGrotesk bg-greyThree rounded-sm shadow-lg -m-10">
                      {t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_14')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null }
        </div>
        {carbonFootprintDistribution?.length > 0 ? (
          <>
            <button type="button" onClick={toggleTable} className="border-0 bg-white text-sm mt-4 text-greyFour hover:text-greyThree font-poppins underline w-fit">
              {displayTable ? t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_22') : t('CARBON_FOORPRINT_DISTRIBUTION_TEXT_15')}
            </button>
            <div style={{ maxHeight: (displayTable) ? '999px' : '0px', overflow: 'hidden' }} className="transition-all duration-1000 ease-in-out me-0 md:mr-4">
              {chartData
                .sort((a, b) => b.value - a.value)
                .map((item, index) => (
                  <div key={index} className="mt-3">
                    {/* Container qui passe de vertical à horizontal selon breakpoint */}
                    <div className="flex flex-col md:grid md:grid-cols-12">
                      {/* Label */}
                      <div className="text-sm font-normal md:col-span-6">
                        {t(item.id)}
                      </div>
                      {/* Barre de progression et pourcentage */}
                      <div className="flex md:mt-0 md:col-span-6">
                        <div
                          className="h-4 rounded-md mt-1"
                          style={{
                            width: `${item.value}%`,
                            backgroundColor: item.color,
                          }}
                        />
                        <div className="ml-2 text-[14px] font-semibold">
                          {parseFloat(item.value).toFixed(1)}%
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default CarbonFootprintDistribution;
