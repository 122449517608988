import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Dropdown from './Dropdown';

import { TableLoadingContext } from '../../../../context/TableLoadingProvider';

function SbtiSimple() {
  const { setLoading } = useContext(TableLoadingContext);
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const options = [
    {
      name: 'VALIDATED',
      value: 3,
    },
    {
      name: 'ONGOING',
      value: 4,
    },
    {
      name: 'NO_COMMITMENT',
      value: 5,
    },
  ];

  const handleSbtiSimpleFilter = (value) => {
    setLoading(true);
    if (value === '') {
      searchParams.delete('sbti_filter_simple');
      searchParams.set('p', 0);
    } else {
      searchParams.set('sbti_filter_simple', value);
      searchParams.set('p', 0);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('sbti_filter_simple')) {
      setValue(searchParams.get('sbti_filter_simple'));
    } else {
      setValue('');
    }
  }, [searchParams]);

  return (
    <Dropdown
      name="SBT_TARGET_DETAILED"
      handleChange={handleSbtiSimpleFilter}
      options={options}
      value={value}
    />
  );
}

export default SbtiSimple;
