import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchIco from '../../../../assets/ui/search.svg';
import Results from '../Results/Results';

function Big({ profiles, query, setQuery, handleClick }) {
  const { t } = useTranslation();

  const sectionStyle = {
    marginTop: '-40px',
  };

  return (
    <section className="container px-3" style={sectionStyle}>
      <div className="max-w-[464px] min-[993px]:w-[533px]  mx-auto">
        <div className="mt-4 col-md-8 pe-0 ps-0">
          <input
            type="text"
            placeholder={t("Ex: L'Oréal, Danone, Unilever...")}
            className="w-full font-normal rounded-full py-4 pl-14 focus:outline-none shadow-lg"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
          />
          <span>
            <img
              className="search-icon"
              alt="Search Icon"
              src={SearchIco}
            />
          </span>
          {profiles.length > 0 && <Results profiles={profiles} handleClick={handleClick} />}
        </div>
      </div>
    </section>
  );
}

export default Big;
