import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import Features from '../../features.json';
import Presentation from '../Features/Presentation/Presentation';
import Search from '../Features/Search/Search';
import Database from '../Features/Database/Database';
import Login from './Login/Login';
import verifyAuthorization from '../../utils/verifyAuthorization';
import CategoryFilter from '../Features/CatgoryFilter/CategoryFilter';
import { TableLoadingProvider } from '../../context/TableLoadingProvider';

function Homepage() {
  const [filteredCategory, setFilteredCategory] = useState();
  const { partner, features, session } = useOutletContext();
  const hasWriteAccess = verifyAuthorization(session, ['ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']);
  const presentation = features.includes(Features.presentation) && <Presentation partner={partner} features={features} />;
  const search = features.includes(Features.bigSearch) && <Search partner={partner} />;
  const database = features.includes(Features.database) && <Database partner={partner} hasWriteAccess={hasWriteAccess} filteredCategory={filteredCategory} />;
  const categoryFilter = features.includes(Features.categoryFilter) && <CategoryFilter partner={partner} setFilteredCategory={setFilteredCategory} />;

  const login = !session && <Login partner={partner} />;
  return (
    <div id="main-content" className=" flex flex-col items-center px-4 pb-6">
      {search}
      {presentation}
      {categoryFilter}
      <div className="container mb-6 flex-grow">
        <TableLoadingProvider>
          {database}
        </TableLoadingProvider>
      </div>
      {login}
    </div>
  );
}

export default Homepage;
