import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import rightArrow from '../../../../assets/ui/right-arrow-m.svg';

import getLabelsRse from '../../../../utils/getLabelsRse';
import getUserLocal from '../../../../utils/getUserLocal';

function LabelsRse({ profile }) {
  const [items, setItems] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    getLabelsRse(profile.id, getUserLocal()).then((response) => {
      setItems(response);
    });
  }, [profile]);

  return (
    items.length > 0 ? (
      <div className="p-3 xl:pl-4 bg-white mb-4 border border-solid border-greySix rounded">
        <h3 className="text-greyOne text-base ">{t('LABELS_CHART_RSE_TITLE')}</h3>
        {items.map((item, index) => {
          if (item.url) {
            return (
              <a key={index} href={item.url} className={`flex hover:bg-backgroundCleanBlue no-underline text-sm ${items.length !== (index + 1) ? 'py-3 border-b border-solid border-t-0 border-x-0 border-greySix' : 'pt-3'}`} target="_blank" rel="noreferrer">
                <div className="flex flex-col" style={{ lineHeight: '18px' }}>
                  <div className="font-semibold text-greyOne">
                    {item.name}
                  </div>
                  <div className="pt-2 text-greyFour">
                    {item.data}
                  </div>
                </div>
                <div className="p-0 my-auto">
                  <div className="no-underline font-semibold">
                    <img src={rightArrow} alt="Right arrow" />
                  </div>
                </div>
              </a>
            );
          }
          return (
            <div key={index} className={`flex text-sm ${items.length !== (index + 1) ? 'border-b border-solid border-t-0 border-x-0 border-greySix py-3' : 'pt-3'}`}>
              <div className="flex flex-col" style={{ lineHeight: '18px' }}>
                <div className="font-semibold text-greyOne">
                  {item.name}
                </div>
                <div className="pt-2 text-greyFour">
                  {item.data}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    ) : ('')
  );
}

export default LabelsRse;
